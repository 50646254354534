import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-waterbury-connecticut.png'
import image0 from "../../images/cities/scale-model-of-lake-compounce-in-waterbury-connecticut.png"
import image1 from "../../images/cities/scale-model-of-mount-southington-ski-area-in-waterbury-connecticut.png"
import image2 from "../../images/cities/scale-model-of-naugatuck-historical-society-in-waterbury-connecticut.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Waterbury'
            state='Connecticut'
            image={image}
            lat='41.5585'
            lon='-73.0367'
            attractions={ [{"name": "Lake Compounce", "vicinity": "185 Enterprise Dr, Bristol", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 41.64123499999999, "lng": -72.92318660000001}, {"name": "Mount Southington Ski Area", "vicinity": "396 Mt Vernon Rd, Plantsville", "types": ["point_of_interest", "establishment"], "lat": 41.5820605, "lng": -72.92492010000001}, {"name": "Naugatuck Historical Society", "vicinity": "171 Church St, Naugatuck", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.4897686, "lng": -73.05547790000003}] }
            attractionImages={ {"Lake Compounce":image0,"Mount Southington Ski Area":image1,"Naugatuck Historical Society":image2,} }
       />);
  }
}